import React, { useState } from 'react'
import Props from 'prop-types'
import { Link } from 'gatsby'

import { isWeb, getContent, getLn } from 'common/helpers'
import Page from 'components/Page'
import Navigation from 'components/Navigation'
import Container from 'components/Styled/Container'
import { StyledInputBox, StyledInput } from 'components/Search'
import { H2, H2Mobile, UlSecondary } from 'components//Styled/Typography'
import {
  PageContent,
  Section,
  SECTION_LAYOUT_WIDE,
} from 'components//Styled/Layout'
import { languages } from '../common/constants'
import qs from 'qs'

const getFaqs = () => {
  // eslint-disable-next-line no-undef,require-unicode-regexp
  const context = require.context('../data/faqs', false, /\.*\.json$/)
  const collection = getContent(context)

  return collection
}

const QA = ({ pageContext: lang }) => {
  const [search, setSearch] = useState('')
  const data = getFaqs()

  const rawQueryParams = typeof location !== 'undefined' ? location.search : ''
  const ln = getLn(rawQueryParams)
  const language = languages.includes(ln) ? ln : 'en'
  // eslint-disable-next-line require-unicode-regexp
  const re = new RegExp(`${search}`, 'i')
  const filteredQuestions = []

  data.forEach((section, sectionIdx) => {
    section.faq.forEach((q, questionIdx) => {
      if (re.test(q[`question-${language}`])) {
        const url =
          lang.lang.slug === 'en'
            ? `/s-${sectionIdx}-q-${questionIdx}${rawQueryParams}`
            : `/${lang.lang.slug}/s-${questionIdx}-q-${questionIdx}${rawQueryParams}`

        filteredQuestions.push(
          <li key={`${section[`title-${language}`]}${questionIdx}`}>
            <Link to={url}>{q[`question-${language}`]}</Link>
          </li>
        )
      }
    })
  })

  // eslint-disable-next-line no-shadow
  const handleOnChange = event => {
    setSearch(event.target.value)
  }

  return (
    <Page title="Q&amp;A" translates={lang.lang}>
      <Navigation
        isMobileVisible={isWeb(rawQueryParams)}
        translates={lang.lang}
      />
      <Container>
        <PageContent isMobileVisible={isWeb(rawQueryParams)}>
          <H2>What can we help you with?</H2>
          <StyledInputBox>
            <StyledInput
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleOnChange}
            />
          </StyledInputBox>
          <Section layout={SECTION_LAYOUT_WIDE}>
            {!search ? (
              <>
                {data.map(
                  (section, idx) =>
                    section['title-en'] &&
                    section.faq.length && (
                      <>
                        {section.faq.filter(q => q[`question-${ln}`]).length ? (
                          <H2Mobile>{section[`title-${ln}`]}</H2Mobile>
                        ) : (
                          ''
                        )}

                        <UlSecondary>
                          {section.faq.map((q, index) => {
                            const qsParams = qs.parse(rawQueryParams, {
                              ignoreQueryPrefix: true,
                            })
                            const {
                              // eslint-disable-next-line no-unused-vars
                              s: sectionIteem,
                              // eslint-disable-next-line no-unused-vars
                              q: questionItem,
                              ...qsParamsReduced
                            } = qsParams
                            const qsParamsX = {
                              ...qsParamsReduced,
                              s: idx,
                              q: index,
                            }
                            const params = `?${qs.stringify(qsParamsX)}`

                            const url =
                              lang.lang.slug === 'en'
                                ? `/answer/${params}`
                                : `/${lang.lang.slug}/answer/${params}`

                            return (
                              q[`question-${language}`] && (
                                <li>
                                  <Link to={url}>
                                    {q[`question-${language}`]}
                                  </Link>
                                </li>
                              )
                            )
                          })}
                        </UlSecondary>
                      </>
                    )
                )}
              </>
            ) : (
              <>
                <H2Mobile>Results</H2Mobile>
                <UlSecondary>
                  {filteredQuestions.length ? filteredQuestions : 'No results'}
                </UlSecondary>
              </>
            )}
          </Section>
        </PageContent>
      </Container>
    </Page>
  )
}

QA.propTypes = {
  location: Props.shape({
    search: Props.string,
  }).isRequired,
  pageContext: Props.any.isRequired,
}

export default QA
