export const languages = [
  'en',
  'de',
  'fr',
  'zh',
  'zh_CN',
  'zh_TW',
  'ja',
  'ko',
  'pt',
  'es',
  'ru',
  'cs',
  'vi',
  'nl',
]
