import styled from 'styled-components'

export const StyledInputBox = styled.div`
  margin: 30px 0 50px;
`

export const StyledInput = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 2px solid rgba(153, 163, 166, 0.23);
  outline: none;
  font-size: 1.6rem;
  font-weight: 300;
  color: #99a3a6;
  padding: 0.5rem 0;
  background-position: -5px 50%;
  background-size: 24px 24px;
  padding-left: 25px;
`
